<template>
  <main role="main" class="container">
    <article>
      <h1 class="mb-4">{{ $t("pages.payroll-employees.title") }}</h1>

      <div class="card payroll-searching mb-4">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="search-header">{{ $t("pages.payroll.searching_header") }}</span>
              <img src="@/assets/IC_Minimise.png" alt="min" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <form>
                <div class="form-row">
                  <div class="col-md-2">
                    <year-field :form="form" name="year" />
                  </div>
                  <div class="col-md-5">
                    <employee-list-field :form="form" name="employee" />
                  </div>
                  <div class="col-md-4 offset-md-1">
                    <pay-roll-status-field :form="form" name="signed" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col text-right">
              <a href="#" class="mx-4 search-clean" @click.prevent="clear">{{
                $t("components.actions.clear")
              }}</a>
              <vue-button
                :title="$t('components.actions.search')"
                class="btn-primary search-button"
                @click="init"
              ></vue-button>
            </div>
          </div>
        </div>
      </div>

      <vue-data-table
        :table-columns="columns"
        :table-rows="payrolls"
        :is-loading="isLoading"
        :show-row-table-action="false"
        :empty-rows-message="$t('components.table.no_data')"
      >
        <template #table-row="props">
          <template v-if="props.cell.getColumn.id !== 'employeeName'">
            <img
              src="@/assets/IC_Check_negro.svg"
              alt="alert"
              v-if="$isset(props.cell.getValue) && props.cell.getValue"
            />
            <img
              src="@/assets/IC_Close_red.svg"
              alt="alert"
              v-else-if="$isset(props.cell.getValue) && !props.cell.getValue"
            />
          </template>
          <template v-else>
            <router-link
              :to="{
                name: 'profile-employee',
                params: { code: props.row.raw.employeeCode },
              }"
              class="nav-link"
            >
              {{ props.cell.getValue }}
            </router-link>
          </template>
        </template>
      </vue-data-table>
    </article>
  </main>
</template>

<script>
import api from "@/api/payroll";
import VueDataTable, { Column } from "@/components/table";
import { SUC_SELECT_CHANGED_EVENT } from "@/components/form";
import { EmployeeListField, PayRollStatusField, YearField } from "@/pages/components/fields";

import { PayRollYearStatus } from "./index";
import { VueButton, Form, Options } from "@/components/form";

import { WhoAmIMixin } from "@/components/mixins";

export default {
  mixins: [WhoAmIMixin],
  name: "PayRollPage",
  layout: "MainLayout",
  components: {
    VueDataTable,
    VueButton,
    EmployeeListField,
    PayRollStatusField,
    YearField,
  },
  middleware: ["auth", "notification"],
  data() {
    return {
      isLoading: false,
      payrolls: [],
      form: new Form({
        data: {
          signed: null,
          employee: null,
          year: new Date().getFullYear(),
        },
        options: {
          ...new Options().add("employee", "employeeCode"),
        },
      }),
      columns: [
        new Column({
          id: "employeeName",
          name: "pages.payroll-responsible.employee",
          styleHeader: "min-width: 300px;",
        }),
        new Column({
          id: "januarySigned",
          name: "components.date.month_short[0]",
        }),
        new Column({
          id: "februarySigned",
          name: "components.date.month_short[1]",
        }),
        new Column({
          id: "marchSigned",
          name: "components.date.month_short[2]",
        }),
        new Column({
          id: "aprilSigned",
          name: "components.date.month_short[3]",
        }),
        new Column({
          id: "maySigned",
          name: "components.date.month_short[4]",
        }),
        new Column({
          id: "juneSigned",
          name: "components.date.month_short[5]",
        }),
        new Column({
          id: "julySigned",
          name: "components.date.month_short[6]",
        }),
        new Column({
          id: "augustSigned",
          name: "components.date.month_short[7]",
        }),
        new Column({
          id: "septemberSigned",
          name: "components.date.month_short[8]",
        }),
        new Column({
          id: "octoberSigned",
          name: "components.date.month_short[9]",
        }),
        new Column({
          id: "novemberSigned",
          name: "components.date.month_short[10]",
        }),
        new Column({
          id: "decemberSigned",
          name: "components.date.month_short[11]",
        }),
      ],
    };
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    // EventBus.off(PAYROLL_SIGNED_EVENT);
  },
  computed: {
    employeeChangeEvent() {
      return SUC_SELECT_CHANGED_EVENT;
    },
  },
  methods: {
    init() {
      this.isLoading = true;
      var data = this.form.data();

      api
        .getPayrollStatusEmployeeOfResponsible(data.year ?? new Date().getFullYear(), {
          ...data,
        })
        .then((response) => {
          let payrolls = [];
          response.data.forEach((element) => {
            payrolls.push(new PayRollYearStatus().parse(element));
          });

          this.payrolls = payrolls;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    clear() {
      this.form.reset();
      this.init();
    },
    employeeChanged(data) {
      if (this.$isset(data)) {
        this.employeeCode = data.employeeCode;
      } else {
        this.employeeCode = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payroll-action {
  padding-left: 5px;
  color: $color-primary;

  & > img {
    margin-right: 5px;
  }
}

.payroll-searching {
  .search-header {
    color: $color-secondary;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-right: 5px;
  }

  .search-button {
    border-radius: $border-radius;
    height: 50px;
    width: 125px;
  }

  .search-clean {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }
}
</style>
